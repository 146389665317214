import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config';
import { BaseService } from '../base.service';
import { Company } from './company';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { CompanyUtils } from './company-utils';
import { BehaviorSubject, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CompanyService extends BaseService{
  baseURL = `${Config.vWebUrl}busca_company_edp/`;
  companyData: Company;
  private _linkThemeName: BehaviorSubject<string> = new BehaviorSubject('theme-default');

  constructor(private h: HttpClient) {
    super(h);
  }

  get linkThemeName(): BehaviorSubject<string> {
    return this._linkThemeName;
  }

  getCompanyByHandle(company_handle) {
    return this.http.get<Company>(this.baseURL + `${company_handle}/-1`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  getGuestRoomData(company_handle) {
    return this.http.get<Company>(this.baseURL + `-10/${company_handle}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  getAgendamentoCompData(company_id) {
    if (this.companyData?.company_id) {
      return of(this.companyData);
    }
    return this.http.get<Company>(this.baseURL + `-11/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        this.companyData = data.result[0];
        const themeName = this.companyData.linkThemeName ? this.companyData.linkThemeName : 'theme-default';
        this._linkThemeName.next(themeName);
        return this.companyData;
      }),
      catchError(this.handleErrors)
    );
  }

  getCompaniesByCityId(cityId: number){
    const httpParams = new HttpParams({fromObject: {
      cityId,
      systemPlatform: 2
    }});
    return this.http.get<Company[]>(`${this.baseURL}-12`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: httpParams
    }).pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getCompanyByIdToPdv() {
    const companyId = CompanyUtils.getCompanyId();
    return this.http.get<Company>(`${Config.vWebUrl}busca_company_edp_v2/${companyId}`, {
      headers: this.getCommonHeaders_compToken1Conditional(false),
    }).pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }



}
