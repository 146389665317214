import { Component, OnInit } from '@angular/core';
import { Globals } from './svc/globals';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { Router } from '@angular/router';
import { LogUpdateService } from './shared/pwa-updates/log-update.service';
import { CheckForUpdateService } from './shared/pwa-updates/check-for-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.25s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger('delayXShowAnimation',
      [
        state('xHidden', style({
          opacity: 0
        })),
        state('xShown', style({
          opacity: 1
        })),
        transition('* => xShown', [
          animate('500ms 5.00s')
        ])
      ]
    )
  ]
})
export class AppComponent {


  constructor(
    public globals: Globals,
    public router: Router,
    private logUpdate: LogUpdateService,
    private checkUpdate: CheckForUpdateService
  ) {


    this.router.events.pipe().subscribe((e) => {
      window.scrollTo(0,0);
    });

   }

}
