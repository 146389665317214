<div class="topbar autoLayout paddingSmall justify-content-between" *ngIf="globals.showTopBar" [class]="[currentTheme]">

  <div class="autoLayout">

    <img *ngIf="currentTheme == 'theme-dark'" class="appLogo marginHorizontalMedium" src="../../../assets/logo_dark.png" (click)="goToAgendamentoList();"/>
    <img *ngIf="currentTheme != 'theme-dark'" class="appLogo marginHorizontalMedium" src="../../../assets/logo_default.png" (click)="goToAgendamentoList();"/>

  </div>

  <div class="autoLayout justify-content-end flex-wrap">

  <div class="autoLayout" (click)="toggleGlobalsPlayAlarm($event); $event.stopPropagation();" title="Ligar/Desligar alarme de notificação"
  style="cursor: pointer;">
      <!-- Checkbox oculta -->
      <input type="checkbox" id="alarmCheckbox" class="alarm-checkbox"
             [checked]="globals.playAlarm === 1" hidden/>

      <!-- Ícone de sino personalizado -->
      <label for="alarmCheckbox" class="bell-icon">
               <i class="fa fa-bell iconMenu" aria-hidden="true"
               [class]="[currentTheme]"
               [style.opacity]="globals.playAlarm === 1 ? '1' : '0.4'"
               style="font-size: 1.2em; position: relative;"></i>
      </label>
  </div>

    <div class="autoLayout" [ngClass]="{'marginHorizontalMedium' : (globals.showMenuBtn == false)}" >
      <ul ngbNav>
        <li ngbDropdown class="nav-item">
          <a href (click)="false" class="nav-link" ngbDropdownToggle>
            <i class="fa fa-cog iconMenu"
            [class]="[currentTheme]" style="font-size: 1.5em;height: 50%;" aria-hidden="true"></i>
          </a>
          <div ngbDropdownMenu>
            <button *ngIf="isAdmin" ngbDropdownItem (click)="goTo('edit-login')">Alterar email de login</button>
            <button *ngIf="isAdmin" ngbDropdownItem (click)="goTo('new-password')">Alterar senha</button>
            <button ngbDropdownItem (click)="goTo('usage-plans')" *ngIf="planosAssinaturas">Plano/Assinatura</button>
            <button ngbDropdownItem (click)="goTo('employees')" *ngIf="gerenciarFuncionarios">Acesso de funcionários</button>
            <button ngbDropdownItem (click)="goToFinancial2();" *ngIf="gerenciarFuncionarios">Financeiro</button>
            <button ngbDropdownItem (click)="goTo('integracao-pagamento')">Integração de Pagamento</button>
            <button ngbDropdownItem (click)="openExternalLink('https://www.youtube.com/channel/UCaKbRezEFnrOcNNiDyjnWzA/featured');">Tutoriais</button>
            <button ngbDropdownItem (click)="logout()">Sair</button>
          </div>
        </li>
      </ul>
    </div>

  </div>

</div>
