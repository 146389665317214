import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgendamentoPublNewComponent } from './agendamento-publ-new/agendamento-publ-new.component';
// import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { CompanyRegisterComponent } from './company/company-register/company-register.component';
import { AgendamentoConfNewComponent } from './agendamento-conf-new/agendamento-conf-new.component';
import { CompanyLinkTreeComponent } from './company-link-tree/company-link-tree.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { ConfirmaAgendamentoComponent } from './confirma-agendamento/confirma-agendamento.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'empresa/login', pathMatch: 'full'
  },
  // TODO: redirect to ClientRegisterModule if it is used
  // { path: 'cadastro/1', component: CadastrarComponent },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./components/company-not-found/company-not-found.module').then(
        (m) => m.CompanyNotFoundModule
      ),
  },
  { path: ':cid', component: AgendamentoPublNewComponent },
  // { path: 'new-link-design/:cid', component: AgendamentoPublNewComponent },
  { path: 'agendamento/:cid', component: AgendamentoPublNewComponent },
  { path: ':cid/conf', component: AgendamentoPublNewComponent },
  { path: ':cid/catalogo', component: CatalogoComponent },
  { path: 'agendamento/:cid/:date/:hour/:pid', component: AgendamentoPublNewComponent },
  { path: ':cid/confirmacao', component: AgendamentoConfNewComponent },
  { path: ':cid/links', component: CompanyLinkTreeComponent},
  { path: 'confirmar/:cid/:id' , component: ConfirmaAgendamentoComponent},
  {
    path: 'empresa/cadastro',
    loadChildren: () => import('./company/company-register/company-register.module').then(m => m.CompanyRegisterModule),
  },
  {
    path: 'empresa/login',
      loadChildren: () => import('./cadastro-novo/cadastro-novo.module').then(m => m.CadastroNovoModule),
  },
  {
    path: 'agenda/cadastro',
    loadChildren: () => import('./cadastro-novo/cadastro-novo.module').then(m => m.CadastroNovoModule),
  },
  { path: 'agenda/login',
    redirectTo: 'empresa/login'
  },
  {
    path: 'empresa/cadastro-assinatura',
    loadChildren: () =>
      import('./page-pay-register/page-pay-register-routing.module').then(
        (m) => m.PagePayRegisterRoutingModule
      ),
  },
  {
    path: 'campanha/:codigo',
    loadChildren: () =>
      import('./lead-capture/lead-capture.module').then(
        (m) => m.LeadCaptureModule
      ),
  },
  {
    path: 'client/login',
    loadChildren: () =>
      import('./client-login/client-login.module').then(
        (m) => m.ClientLoginModule
      ),
  },
  {
    path: 'client/agendamento-list',
    loadChildren: () =>
      import('./client-agendamento-list/client-agendamento-list.module').then(
        (m) => m.ClientAgendamentoListModule
      ),
  },
  {
    path: 'client/register',
    loadChildren: () =>
      import('./client-register/client-register.module').then(
        (m) => m.ClientRegisterModule
      ),
  },
  {
    path: 'client/reset-password',
    loadChildren: () =>
      import('./client-reset-pass/client-reset-pass.module').then(
        (m) => m.ClientResetPassModule
      ),
  },
  {
    path: 'client/assinatura',
    loadChildren: () =>
      import('./client-assinatura/client-assinatura.module').then(
        (m) => m.ClientAssinaturaModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
